// About.js
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/AboutMe.css';
import PersonSchema from '../seo/PersonSchema';
import AboutSeo from '../seo/AboutSeo';

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      fadeIn: false,
      education: [
        {
          id: "first-p-about",
          school: "National School For Computer Science (ENSIAS)– Rabat, Morocco",
          degree: "Engineer Degree in Web and Mobile Engineering",
          year: "2015 – 2019",
          details: "Specialized in Web and Mobile Engineering, Web Intelligence",
        },
        {
          id: "second-p-about",
          school: "Lycee Mohammed VI",
          degree: "Preparatory Classes",
          year: "2013 – 2015",
          details: "Focused on Mathematics and Physics",
        },
        {
          id: "third-p-about",
          school: "Lycee Technique Prince Moulay Abdellah ",
          degree: "Bachelor of Mathematical Sciences",
          year: "2013",
        },
      ],
    };
  }

  componentDidMount() {
    // Trigger the animation after the component is mounted
    this.setState({ fadeIn: true });
  }

  render() {
    return (
      <section id="about" className={`about-mf sect-pt4 route ${this.state.fadeIn ? 'fade-in' : ''}`}>
        <AboutSeo/>
        <div className="container about-me-container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="container mt-5">
                      <div className="title-box-2">
                        <h5 className="title-left">About me</h5>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Software engineer</h5>
                          <p className="card-text">
                          Proficient in Software development with expertise in Java/Spring and React.js.
                          </p>
                        </div>
                      </div>

                      <div className="card mt-3">
                        <div className="card-body">
                          <h5 className="card-title">Complex projects</h5>
                          <p className="card-text">
                          Successfully executed complex projects in aerospace, finance, and banking sectors.
                          </p>
                        </div>
                      </div>

                      <div className="card mt-3">
                        <div className="card-body">
                          <h5 className="card-title">Technology watch</h5>
                          <p className="card-text">
                          Actively engaged in continuous learning to stay abreast of the latest technologies and methodologies.
                          </p>
                        </div>
                      </div>

                      <div className="card mt-3">
                        <div className="card-body">
                          <h5 className="card-title">Quality Assurance</h5>
                          <p className="card-text">
                          Quality assurance skills to ensure reliability and project quality.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="container mt-5">
                      <div className="title-box-2">
                        <h5 className="title-left">Education</h5>
                      </div>
                      <ul className="list-group">
                        {this.state.education.map((edu) => (
                          <li key={edu.id} className="list-group-item">
                            <h4>{edu.school}</h4>
                            <p>{edu.degree}</p>
                            <p>{edu.year}</p>
                            {edu.details && <p>{edu.details}</p>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PersonSchema/>
      </section>
    );
  }
}

export default About;
